<template >
  <div class="table-container">
    <div class="table-toolbar">
      <div class="title">
        <div class="text">
          <span>企业类型</span>
          <!-- <span class="desc">(一级)</span> -->
        </div>
      </div>
      <div class="opts">
        <a-button class="opt-btn" size="large" type="link" @click="toCreate">新建</a-button>
      </div>
    </div>
    <div class="table">
      <a-table size="middle" class="tableRef" :columns="columns" :row-key="record => record.id" :data-source="dataSource"
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex == 'sort_num'">
            <div class="drapHandle" style="cursor: pointer;">
              <holder-outlined />
              <span style="margin-left:6px;">{{ record.sort_num }}</span>
            </div>
          </template>

          <template v-if="column.dataIndex === 'operation'">
            <div class="operations">
              <a class="btn" @click="edit(record)">编辑</a>
              <a-button type="danger" size="small" @click="del(record)">删除</a-button>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <RoleInMenu ref="roleInMenuRef" @refreshList="handleSearch" />
    <Edit ref="editRef" @close="current = null;" :current="current" @refreshList="handleSearch" />
  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
import { message, Modal } from 'ant-design-vue';
import { HolderOutlined } from '@ant-design/icons-vue';
import Sortable from 'sortablejs';
import Edit from './Edit.vue';

export default defineComponent({
  components: {
    Edit,
    HolderOutlined
  },

  data() {
    return {
      currentTab: '',
      filter: {},
      current: null,
    }
  },
  watch: {
    dataSource(newVal) {
      if(newVal) {
        console.log(newVal);
      this.initDrap();
      }
    }
  },
  methods: {
    initDrap() {
      // console.log('hello');
      this.$nextTick(() => {
        let el = document.querySelector('.tableRef .ant-table-tbody');

        new Sortable(el, {
          handle: '.drapHandle',
          animation: 250,
          onEnd: (evt) => {
            let tempList = [...this.dataSource];
            let temp = tempList.splice(evt.oldIndex, 1)[0];
            tempList.splice(evt.newIndex, 0, temp);
            this.submitSort(tempList);
          }
        })
      })
    },
    async submitSort(list) {
      this.listLoading = true;
      let { msg, code } = await service.set_declare_sort_num({
        id: list.map(m => m.id).join(',')
      });
      this.listLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        // this.getSubList();
      }
    },
    // 切换页码调用获取
    handleTableChange(pag, filter, sorter) {
      this.run({
        limit: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order ? (sorter.order.includes('asc') ? 'asc' : 'desc') : '',
        ...this.filter,
      });
    },
    // 筛选
    handleSearch() {
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filter
      })
    },
    edit(item) {
      this.current = item;
      this.$refs['editRef'].onShow();
    },
    del(item) {
      Modal.confirm({
        title: '警告',
        content: `确认删除[${item.name}]?`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          const { code, msg } = await service.del_ctype({ id: item.id });
          if (code && code == '1') {
            message.success(msg || '成功');
            this.handleSearch();
          }
          else {
            message.error(msg || '失败');
          }
        }
      });
    },
    menuEdit(record) {
      this.$refs['roleInMenuRef'].init(record.id);
    },
    toCreate() {
      this.current = { pid: '0' }
      this.$refs['editRef'].onShow();
    },
  },
  setup() {
    // 声明并调用一次数据拉取
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination(service.get_ctype_lists, {
      formatResult: res => {
        res.data.total_num = res.total_num;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      dataSource,
      pagination,
      loading,
      columns: [
        {
          title: 'ID',
          dataIndex: 'sort_num',
        },
        {
          title: '类型名称',
          dataIndex: 'name',
        },
        // {
        //   title: '分类说明',
        //   dataIndex: 'description',
        // },
        {
          title: '操作',
          dataIndex: 'operation'
        }
      ],
      run,
    };
  },
})
</script>
<style lang="less" scoped></style>