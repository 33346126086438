<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '新建'}企业类型`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="类型名称" name="name">
              <a-input v-model:value="form.name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="排列序号" name="sort_num">
              <a-input type="number" v-model:value="form.sort_num" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-item label="分类说明" name="description">
              <a-input v-model:value="form.description" placeholder="请输入" />
            </a-form-item>
          </a-col> -->
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="附件" name="icon">
              <UploadFile v-model:value="form.icon" />
            </a-form-item>
          </a-col>
        </a-row> -->
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
// import UploadFile from '@/components/common/uploadFile.vue';
import service from '@/service/service';
import { message } from 'ant-design-vue';
const rules = {
  name: [{
    required: true,
    message: '请输入类型名称',
  }],
  sort_num: [{
    required: true,
    message: '请输入分类序号',
  }],
};
export default {
  components: {
    // UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        id: '',
        rolename: '',
        sort_num:'',
        description: '',
      },
      visible: false,
      createLoading: false,
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            temp[key] = nowCurrent[key];
          }
          this.form = temp;
        }
      },
      immediate: true
    }
  },
  methods: {
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
    },
    async onConfirm() {
      this.createLoading = true;
      const api = this.form.id ? 'edit_ctype' : 'add_ctype';
      const { code, msg } = await service[api]({
        ...this.form
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>